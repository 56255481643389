<template>
<div class="menuView">
    <div class="menulist">
        <el-tree :data="treedata" @node-click="handleNodeClick" :props="prop" :expand-on-click-node="false"></el-tree>
    </div>
    <div class="menuFrom">
      <el-button type="primary" @click="addNew">+新建规则</el-button>
      选择菜单：{{clickName}}-{{clickData}}
      <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column prop="model" label="实体类"></el-table-column>
          <el-table-column prop="field" label="字段"></el-table-column>
          <el-table-column prop="conditionKey" label="条件"></el-table-column>
          <el-table-column prop="conditionValue" label="规则值"></el-table-column>
          <el-table-column prop="sqlData" label="自定义sql"></el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="editData(scope.row)" type="text" size="mini">编辑</el-button>
              <el-button @click="delData(scope.row)" type="text" size="mini">删除</el-button>
            </template>
          </el-table-column>
      </el-table>
    </div>
    <el-dialog title="保存数据" v-model="dialogShow" width="50%">
      <el-form label-width="100px">
            <el-form-item label="名称">
                <el-input v-model="dataConfig.name"></el-input>
            </el-form-item>
            <el-form-item label="实体类">
                <el-input v-model="dataConfig.model"></el-input>
            </el-form-item>
            <el-form-item label="字段">
                <el-input v-model="dataConfig.field"></el-input>
            </el-form-item>
            <el-form-item label="条件">
                <el-select v-model="dataConfig.conditionKey">
                    <el-option label="" :value=0></el-option>
                    <el-option label="大于" :value=1></el-option>
                    <el-option label="大于等于" :value=2></el-option>
                    <el-option label="等于" :value=3></el-option>
                    <el-option label="小于" :value=4></el-option>
                    <el-option label="小于等于" :value=5></el-option>
                    <el-option label="模糊匹配" :value=6></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="规则值">
                <el-input v-model="dataConfig.conditionValue"></el-input>
            </el-form-item>
            <el-form-item label="自定义sql">
                <el-input v-model="dataConfig.sqlData" type="textarea"></el-input>
            </el-form-item>
        </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogShow=false">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
</div>
</template>

<script>
import { dataconfig } from './js/config'
import { request } from '@/assets/js/http.js'
import { ElMessage } from 'element-plus'
export default {
  name: 'DataAuthConfig',
  data: function () {
    request('/api/system/menu/treeList', 'post').then((response) => {
      this.treedata = response.data
    })
    return {
      treedata: [],
      tableData: [],
      dialogShow: false,
      dataConfig: { ...dataconfig },
      clickData: '',
      clickName: '',
      prop: {
        label (data, node) {
          return data.label + '-' + data.bz
        }
      }
    }
  },
  methods: {
    addNew: function () {
      if (this.clickData !== '') {
        this.dialogShow = true
        this.dataConfig = { ...dataconfig }
        this.dataConfig.menuId = this.clickData
      }
    },
    editData: function (data) {
      this.dialogShow = true
      this.dataConfig = { ...data }
    },
    reloadData: function () {
      request('/api/system/menu/getMenuDate/' + this.clickData, 'post').then((response) => {
        this.tableData = response.data
      })
    },
    handleNodeClick: function (data) {
      this.clickData = data.id
      this.clickName = data.label
      request('/api/system/menu/getMenuDate/' + data.id, 'post').then((response) => {
        this.tableData = response.data
      })
    },
    submitForm: function () {
      request('/api/system/menu/saveMenuData', 'post', this.dataConfig).then((response) => {
        this.dialogShow = false
        this.reloadData()
        if (response.data === true) {
          ElMessage.success({
            message: '保存成功',
            type: 'success'
          })
        } else {
          ElMessage.error('保存失败')
        }
      })
    },
    delData: function (data) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request('/api/system/menu/deleteMenuData/' + data.id, 'delete').then((response) => {
          if (response.code === '200') {
            ElMessage.success({
              message: '删除成功',
              type: 'success'
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.menuView{
    display: flex;
    padding: 50px;
    .menulist{
        width:30% ;
    }
    .menuFrom{
        width: 70%;
    }
}
</style>
