export var dataconfig = {
  conditionKey: '',
  conditionValue: '',
  createDate: '',
  createUserId: '',
  field: '',
  menuId: '',
  model: '',
  modifyDate: '',
  name: '',
  sqlData: ''
}
